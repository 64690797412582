<template>
    <div class="container-fluid position-relative">
        <div class="row mt-3">
            <transition name="fade">
                <div v-if="mutableDoctors" class="col-md-12">
                    <ul class="nav nav-tabs">
                        <li v-for="(doctor, index) in mutableDoctors" class="nav-item">
                            <a :id="'tab-link'+doctor.id" :ref="'tab-link'+doctor.id" class="nav-link" :class="index === 0 ? 'active' : ''" data-toggle="tab" :href="'#doctor-'+doctor.id">{{doctor.full_name}}</a>
                        </li>
                    </ul>
                    <div class="tab-content mt-4">
                        <div v-for="(doctor, index) in mutableDoctors" class="tab-pane fade" :class="index === 0 ? 'show active' : ''" :id="'doctor-'+doctor.id">
                            <div class="row">
                                <div class="col-md-12 d-flex justify-content-between">
                                    <h3>Horaires de {{doctor.full_name}}</h3>
                                    <a href="#" @click.prevent="onAdd(doctor)"><h3 class="text-success"><i class="bi bi-plus-square-fill"></i></h3></a>
                                </div>
                            </div>
                            <div class="row mt-4 mb-4" v-if="weekdays">
                                <div v-for="day in workingDays()" class="col-lg-2">
                                    <h4>{{day.label}}</h4>
                                    <div v-for="opening_hour in getOpeningHoursForDay(doctor, day.id)" :key="'opening_hour-'+opening_hour.id" >
                                        <div class="card bg-light flex-grow-1">
                                            <div class="card-body">
                                                <span><i class="bi bi-alarm"></i> {{ opening_hour.opening_time | time }} - {{ opening_hour.closing_time | time }}</span>
                                                <div v-for="reason in opening_hour.appointment_reasons">
                                                    <span><i class="bi bi-check text-success"></i> {{ reason.label }}</span>
                                                </div>
                                                <div v-if="opening_hour.office"><i class="bi bi-building"></i> {{ opening_hour.office.name }}</div>
                                            </div>
                                            <div class="card-footer text-right">
                                                <button @click.prevent="onEdit(opening_hour, doctor)" class="btn btn-success btn-sm mr-3"><i class="bi bi-pencil-square"></i></button>
                                                <button @click.prevent="onDelete(opening_hour, doctor)" class="btn btn-danger btn-sm"><i class="bi bi-trash"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import EWeekDays from "../../../enums/EWeekDays";
    import {EventBus} from "../../../event-bus";
    import axios from 'axios'
    import {mapGetters} from "vuex";
    import {namespaces} from "../../../store/namespaces";
    import moment from 'moment'
    export default {
        name: "HorairesWrapper",
        filters: {
            time: (value)=> {
                return moment(value, 'HH:mm:ss').format('HH[h]mm')
            }
        },
        props: {
            doctors: {
                required: true,
                type: Array
            },
        },
        data() {
            return {
                mutableDoctors: null,
                weekdays: EWeekDays,
                selectedDoctorId: null
            }
        },
        computed: {
            ...mapGetters({
                getUser: namespaces.auth + 'getUser',
            }),
        },
        methods: {
            getOpeningHoursForDay(doctor, dayId) {
                return doctor && doctor.opening_hours ? doctor.opening_hours.filter(o=> o.day_of_week === dayId) : []
            },
            onAdd(doctor) {
                EventBus.$emit('opening-hour-create', {doctor: doctor})
                $('#opening-hour-create-modal').modal('show')
            },
            async onDelete(opening_hour, doctor) {
                $('#opening-hour-delete-modal').modal('show')
                EventBus.$on('opening-hour-delete-confirmation',async (result)=> {
                    if(result) {
                        try {
                            let res = await axios.post('opening-hour/delete', {opening_hour_id: opening_hour.id, doctor_id: doctor.id}, {headers: {
                                    "content-type": "application/json",
                                    "Accept": "application/json, text/plain",
                                    "Authorization": "Bearer " + this.getUser.api_token
                                }})
                            this.refreshDoctor(res.data.doctor)
                            Vue.$toast.open({
                                message: 'Horaire supprimé avec succès!',
                                type: 'success',
                            });
                        }catch(e) {
                            Vue.$toast.open({
                                message: 'Une erreur s\'est produite, veuillez vérifier votre connexion internet.',
                                type: 'error',
                            });
                            console.log(e)
                        }
                        EventBus.$emit('opening-hour-delete-close-modal')
                        EventBus.$off('opening-hour-delete-confirmation')
                    }
                })
            },
            onEdit(opening_hour, doctor) {
                EventBus.$emit('opening-hour-edit', {opening_hour: opening_hour, doctor: doctor})
                $('#opening-hour-edit-modal').modal('show')
            },
            refreshDoctor(doctor) {
                let index = this.mutableDoctors.findIndex(d=> d.id === doctor.id)
                Vue.set(this.mutableDoctors, index, doctor)
            },
            workingDays() {
                return this.weekdays && this.weekdays.getList() ? this.weekdays.getList().filter(d=> d.id !== 0) : []
            }
        },
        mounted() {
            this.mutableDoctors = JSON.parse(JSON.stringify(this.doctors))
            EventBus.$on('opening-hour-update-confirm', (doctor)=> this.refreshDoctor(doctor))
            EventBus.$on('opening-hour-create-confirm', (doctor)=> this.refreshDoctor(doctor))
            if(this.$route.params.doctorId) {
                this.$nextTick(()=> {
                    this.$refs['tab-link'+this.$route.params.doctorId][0].click()
                })
            }
        },
        beforeDestroy() {
            EventBus.$off('opening-hour-delete-confirmation')
            EventBus.$off('opening-hour-update-confirm')
            EventBus.$off('opening-hour-create-confirm')
        }
    }
</script>

<style scoped>
</style>
