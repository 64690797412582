import { render, staticRenderFns } from "./HorairesWrapper.vue?vue&type=template&id=6b65f690&scoped=true"
import script from "./HorairesWrapper.vue?vue&type=script&lang=js"
export * from "./HorairesWrapper.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b65f690",
  null
  
)

export default component.exports