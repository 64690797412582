<template>
    <div>
        <transition name="fade">
            <div v-if="!doctors" id="main-spinner" class="spinner-border" role="status">
            </div>
            <horaires-wrapper v-else :doctors="doctors"></horaires-wrapper>
        </transition>
    </div>
</template>

<script>
    import HorairesWrapper from "../../../components/back/horaires/HorairesWrapper";
    import axios from 'axios'
    import {mapActions, mapGetters} from "vuex";
    import {namespaces} from "../../../store/namespaces";
    export default {
        name: "Index",
        components: {HorairesWrapper},
        computed: {
            ...mapGetters({
                getUser: namespaces.auth + 'getUser',
                getOffices: namespaces.calendar+'getOffices',
            }),
        },
        methods: {
            ...mapActions({
                setOffices: namespaces.adminCalendar+'setOffices',
            }),
        },
        data() {
            return {
                doctors: null,
            }
        },
        async mounted() {
            console.log(this.getOffices)
            if(!this.getOffices) await this.setOffices()
            let res  = await axios.get('v1/admin/opening-hours?app_id='+Vue.$config.app_id, {headers: {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + this.getUser.api_token
            }})
            this.doctors = res.data.doctors
            console.log(this.getOffices)
        }
    }
</script>

<style scoped>
    #main-spinner {
        position: fixed;
        right: 0;
        top:10px;
        z-index: 10000;
    }
</style>
